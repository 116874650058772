import { combineReducers } from "redux";

import UserReducer from "./reducers/user.reducer";
import ThemeReducer from "./reducers/theme.reducer";
import ModalReducer from "./reducers/modal.reducer";
import VideoReducer from "./reducers/video.reducer";
import ResumeReducer from "./reducers/resume.reducer";
import UploadReducer from "./reducers/upload.reducer";
import PointsReducer from "./reducers/points.reducer";
import NetworkReducer from "./reducers/network.reducer";
import VideoBarReducer from "./reducers/videobar.reducer";
import VideoPreference from "./reducers/video_preference";
import locPointsReducer from "./reducers/loc_points.reducer";
import VideoMetaDataReducer from "./reducers/video_meta_data";
import EnrollmentReducer from "./reducers/enrollments.reducer";
import AddCourseDetails from "./reducers/upload-course.reducer";
import AddBundleDetails from "./reducers/upload-bundle.reducer";
import UserDetailsReducer from "./reducers/userDetails.reducer";
import EntityReducer from "./reducers/entity.reducer";
import TourReducer from "./reducers/tour.reducer";

const allReducers = combineReducers({
  user: UserReducer,
  userDetails: UserDetailsReducer,
  theme: ThemeReducer,
  modal: ModalReducer,
  video: VideoReducer,
  resume: ResumeReducer,
  upload: UploadReducer,
  entity: EntityReducer,
  points: PointsReducer,
  tours: TourReducer,
  network: NetworkReducer,
  videobar: VideoBarReducer,
  loc_points: locPointsReducer,
  enrollment: EnrollmentReducer,
  videoMetaData: VideoMetaDataReducer,
  videoPreference: VideoPreference,
  courseDetails: AddCourseDetails,
  bundleDetails: AddBundleDetails,
});

export default allReducers;
