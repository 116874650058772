import types from "../types";

const initialState = 0;

const Local_PointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.locPointsTypes.RESET_LOCAL_POINTS:
      return action.payload;

    case types.locPointsTypes.UPDATE_LOCAL_POINTS:
      return action.payload;

    default:
      return state;
  }
};

export default Local_PointsReducer;
