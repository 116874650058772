import types from "../types";

const initialState = {};

const EntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.entityTypes.ADD_ENTITY:
      return action.payload || {};

    case types.entityTypes.RESET_ENTITY:
      return {};

    default:
      return state;
  }
};

export default EntityReducer;
