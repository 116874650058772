import types from "../types";

let initialState = [];

/*
id: string
currsecs: string
initiate: number
lastplaystatus: number
status: boolean
views: boolean
*/

const Video_Meta_Data = (state = initialState, action) => {
  switch (action.type) {
    case types.videoMetaDataTypes.ADD_VIDEO_META_DATA: {
      const flag = state.find(
        (item) => item.status && item.id !== action.payload.id
      );
      let updateMetaData = state.map((item) => {
        if (item.id === action.payload.id) {
          return { ...flag, id: flag.id, status: false };
        } else {
          return item;
        }
      });
      let updatedArr = [...updateMetaData, action.payload];
      if (updatedArr?.length > 15) {
        updatedArr.shift();
      }
      return updatedArr;
    }

    case types.videoMetaDataTypes.ADD_CURR: {
      const result = state.find((item) => item.id === action.payload.id);
      let updatedValue;
      if (result) {
        // update the existing metadata
        updatedValue = {
          ...result,
          data: action.payload?.data,
        };
        const updateData = state?.map((item) => {
          if (item.id === action.payload.id) {
            return updatedValue;
          } else return item;
        });
        return updateData;
      } else {
        // add a new metadata
        updatedValue = {
          id: action.payload.id,
          data: action.payload?.data,
        };
        return [...state, updatedValue];
      }
    }

    case types.videoMetaDataTypes.RESET_VIDEO_META_DATA: {
      return initialState;
    }

    case types.videoMetaDataTypes.ADD_BULK_VIDEO_META_DATA: {
      return action.payload;
    }

    case types.videoMetaDataTypes.UPDATE_VIDEO_META_DATA: {
      const flag = state.find(
        (item) => item.status && item.id !== action.payload.id
      );
      let updatedArr = [
        ...state.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          } else if (flag && item.id === flag?.id) {
            return { ...flag, id: flag.id, status: false };
          } else {
            return item;
          }
        }),
      ];
      return updatedArr;
    }

    default:
      return state;
  }
};

export default Video_Meta_Data;
