import types from "../types";

const initialState = {
  newVideoPage: false,
  certificate: false,
  postEnroll: false,
  preEnroll: false,
  notes: false,
};

const TourReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.tourTypes.CERTIFICATE_TOUR_START:
      return {
        ...state,
        certificate: true,
      };
    case types.tourTypes.CERTIFICATE_TOUR_STOP:
      return {
        ...state,
        certificate: false,
      };

    case types.tourTypes.POST_ENROLL_TOUR_START:
      return {
        ...state,
        postEnroll: true,
      };
    case types.tourTypes.POST_ENROLL_TOUR_STOP:
      return {
        ...state,
        postEnroll: false,
      };

    case types.tourTypes.PRE_ENROLL_TOUR_START:
      return {
        ...state,
        preEnroll: true,
      };
    case types.tourTypes.PRE_ENROLL_TOUR_STOP:
      return {
        ...state,
        preEnroll: false,
      };
    case types.tourTypes.NOTES_TOUR_START:
      return {
        ...state,
        notes: true,
      };
    case types.tourTypes.NOTES_TOUR_STOP:
      return {
        ...state,
        notes: false,
      };
    case types.tourTypes.NEW_VIDEO_PAGE_TOUR_START:
      return {
        ...state,
        newVideoPage: true,
      };
    case types.tourTypes.NEW_VIDEO_PAGE_TOUR_STOP:
      return {
        ...state,
        newVideoPage: false,
      };

    default:
      return state;
  }
};

export default TourReducer;
