"use client";

import { useEffect, useReducer } from "react";
import socketInstance from "@/lib/socket";
import {
  defaultSocketContextState,
  SocketContextProvider,
  SocketReducer,
} from "./Context";

const SocketContextComponent = (props) => {
  const { children } = props;

  const [SocketState, SocketDispatch] = useReducer(
    SocketReducer,
    defaultSocketContextState
  );

  useEffect(() => {
    socketInstance.connect();
    SocketDispatch({
      type: "update_socket",
      payload: socketInstance.getSocket(),
    });

    return () => {
      socketInstance.disconnect();
    };
  }, []);

  return (
    <SocketContextProvider value={{ SocketState }}>
      {children}
    </SocketContextProvider>
  );
};

export default SocketContextComponent;
