import types from "../types";

const initialState = {};

const PointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.pointsTypes.FETCH_USER_TODAYS_POINTS:
      return action.payload;

    case types.pointsTypes.UPDATE_USER_LOCAL_POINTS:
      return action.payload;

    default:
      return state;
  }
};

export default PointsReducer;
