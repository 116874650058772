import types from "../types";

const initialState = [];

const NetworkReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.networkTypes.FETCH_NETWORK_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

export default NetworkReducer;
