import types from "../types";

const initialState = {
  loading: false,
  users: {},
  error: "",
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.userTypes.FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.userTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };

    case types.userTypes.FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        users: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
