import types from "../types";

const initialState = {
  signinModal: false,
  alertModal: false,
  certificateModal: false,
  creatorModal: false,
  feedbackModal: false,
  assignmentModal: false,
  videoModal: false,
  uploadLinkModal: false,
  interestModal: false,
  passwordModal: false,
  pointsModal: false,
  profileModal: false,
  filterModal: false,
  stageModal: false,
  aboutModal: false,
  experienceModal: false,
  educationModal: false,
  skillModal: false,
  addCertificateModal: false,
  awardModal: false,
  projectModal: false,
  contactModal: false,
  requestRecommendationModal: false,
  viewContact: false,
  alumniModal: false,
  redeemModal: false,
  videoUpdateModal: false,
  introModal: false,
  policyModal: false,
  resourceModal: false,
  confirmDetailsModal: false,
  publishCourseModal: false,
  deleteStageModal: false,
  deleteContentModal: false,
  reviewModal: false,
  testimonialModal: false,
  deleteUserModal: false,
  testLinkModal: false,
  videoPageModal: false,
  progressBarModal: false,
  premiumCourseEnrollModal: false,
  violationError: false,
};

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.modalTypes.TOGGLE_SIGNIN_MODAL:
      return {
        ...state,
        signinModal: !state.signinModal,
      };
    case types.modalTypes.TOGGLE_ALERT_MODAL:
      return {
        ...state,
        alertModal: !state.alertModal,
      };
    case types.modalTypes.TOGGLE_CERTIFICATE_MODAL:
      return {
        ...state,
        certificateModal: !state.certificateModal,
      };
    case types.modalTypes.TOGGLE_CREATOR_MODAL:
      return {
        ...state,
        creatorModal: !state.creatorModal,
      };
    case types.modalTypes.TOGGLE_FEEDBACK_MODAL:
      return {
        ...state,
        feedbackModal: !state.feedbackModal,
      };
    case types.modalTypes.TOGGLE_CREATE_ASSIGNMENT_MODAL:
      return {
        ...state,
        assignmentModal: !state.assignmentModal,
      };
    case types.modalTypes.TOGGLE_VIDEO_MODAL:
      return {
        ...state,
        videoModal: !state.videoModal,
      };
    case types.modalTypes.TOGGLE_UPLOAD_LINK_MODAL:
      return {
        ...state,
        uploadLinkModal: !state.uploadLinkModal,
      };
    case types.modalTypes.TOGGLE_INTEREST_MODAL:
      return {
        ...state,
        interestModal: !state.interestModal,
      };
    case types.modalTypes.TOGGLE_PASSWORD_MODAL:
      return {
        ...state,
        passwordModal: !state.passwordModal,
      };
    case types.modalTypes.TOGGLE_PROFILE_MODAL:
      return {
        ...state,
        profileModal: !state.profileModal,
      };
    case types.modalTypes.SEARCH_FILTER_MODAL:
      return {
        ...state,
        filterModal: !state.filterModal,
      };
    case types.modalTypes.POINTS_MODAL:
      return {
        ...state,
        pointsModal: !state.pointsModal,
      };
    case types.modalTypes.STAGE_MODAL:
      return {
        ...state,
        stageModal: !state.stageModal,
      };
    // for profile modals
    case types.modalTypes.ADD_ABOUT_MODAL:
      return {
        ...state,
        aboutModal: !state.aboutModal,
      };
    case types.modalTypes.ADD_EXPERIENCE_MODAL:
      return {
        ...state,
        experienceModal: !state.experienceModal,
      };
    case types.modalTypes.ADD_CERTIFICATE_MODAL:
      return {
        ...state,
        addCertificateModal: !state.addCertificateModal,
      };
    case types.modalTypes.ADD_SKILL_MODAL:
      return {
        ...state,
        skillModal: !state.skillModal,
      };
    case types.modalTypes.ADD_EDUCATION_MODAL:
      return {
        ...state,
        educationModal: !state.educationModal,
      };
    case types.modalTypes.ADD_AWARD_MODAL:
      return {
        ...state,
        awardModal: !state.awardModal,
      };
    case types.modalTypes.ADD_PROJECT_MODAL:
      return {
        ...state,
        projectModal: !state.projectModal,
      };
    case types.modalTypes.ADD_CONTACT_MODAL:
      return {
        ...state,
        contactModal: !state.contactModal,
      };
    case types.modalTypes.REQUEST_RECOMMENDATION_MODAL:
      return {
        ...state,
        requestRecommendationModal: !state.requestRecommendationModal,
      };
    case types.modalTypes.VIEW_CONTACT:
      return {
        ...state,
        viewContact: !state.viewContact,
      };
    case types.modalTypes.ALUMNI_MODAL: // case :  alumnimodal
      return {
        ...state,
        alumniModal: !state.alumniModal,
      };
    case types.modalTypes.REDEEM_MODAL:
      return {
        ...state,
        redeemModal: !state.redeemModal,
      };
    case types.modalTypes.VIDEO_UPDATE_MODAL:
      return {
        ...state,
        videoUpdateModal: !state.videoUpdateModal,
      };
    case types.modalTypes.INTRO_MODAL:
      return {
        ...state,
        introModal: !state.introModal,
      };

    case types.modalTypes.RESOURCES_MODAL:
      return {
        ...state,
        resourceModal: !state.resourceModal,
      };
    case types.modalTypes.CONFIRM_DETAILS_MODAL:
      return {
        ...state,
        confirmDetailsModal: !state.confirmDetailsModal,
      };
    case types.modalTypes.POLICY_MODAL:
      return {
        ...state,
        policyModal: !state.policyModal,
      };
    case types.modalTypes.PUBLISH_COURSE_MODAL:
      return {
        ...state,
        publishCourseModal: !state.publishCourseModal,
      };
    case types.modalTypes.DELETE_CONTENT_MODAL:
      return {
        ...state,
        deleteContentModal: !state.deleteContentModal,
      };
    case types.modalTypes.DELETE_STAGE_MODAL:
      return {
        ...state,
        deleteStageModal: !state.deleteStageModal,
      };
    case types.modalTypes.REVIEW_MODAL:
      return {
        ...state,
        reviewModal: !state.reviewModal,
      };
    case types.modalTypes.TESTIMONIAL_MODAL: {
      return {
        ...state,
        testimonialModal: !state.testimonialModal,
      };
    }
    case types.modalTypes.DELETE_USER_MODAL: {
      return {
        ...state,
        deleteUserModal: !state.deleteUserModal,
      };
    }
    case types.modalTypes.TEST_LINK_MODAL:
      return {
        ...state,
        testLinkModal: action?.payload ? action?.payload : !state.testLinkModal,
      };
    case types.modalTypes.VIDEO_PAGE_MODAL:
      return {
        ...state,
        videoPageModal: action?.payload
          ? action?.payload
          : !state.videoPageModal,
      };
    case types.modalTypes.PROGRESS_BAR_MODAL:
      return {
        ...state,
        progressBarModal: !state.progressBarModal,
      };
    case types.modalTypes.PREMIUM_COURSE_ENROLL:
      return {
        ...state,
        premiumCourseEnrollModal: !state.premiumCourseEnrollModal,
      };
    case types.modalTypes.VIOLATION_ERROR_MODAL:
      return {
        ...state,
        violationError:
          action.payload === true || action.payload === false
            ? action.payload
            : !state.violationError,
      };

    default:
      return state;
  }
};

export default ModalReducer;
