import types from "../types";

const initialState = {
  courseInfo: null,
};

const AddCourseDetails = (state = initialState, action) => {
  switch (action.type) {
    case types.UploadCourse.ADD_COURSE_DETAILS:
      return {
        ...state,
        courseInfo: {
          ...state.courseInfo,
          ...action.payload,
        },
      };

    case types.UploadCourse.ADD_CURRICULUM:
      return {
        ...state,
        stageInfo: action.payload,
      };
    case types.UploadCourse.ADD_STAGE:
      return {
        ...state,
        stageInfo: state.stageInfo.map((stage, i) => {
          if (i === action.payload) {
            return {
              ...stage,
              stage: action.payload,
            };
          }
          return stage;
        }),
      };

    case types.UploadCourse.ADD_LECTURE:
      return {
        ...state,
        stageInfo: state.lecture.stageInfo.map((stage, i) => {
          if (i === action.payload) {
            return {
              ...stage,
              stage: action.payload,
            };
          }
          return stage;
        }),
      };
    default:
      return state;
  }
};

export default AddCourseDetails;
