import { act } from "react";
import types from "../types";

const initialState = {
  isEnrolled: false,
  isRestricted: false,
  playStatus: "",
  playrate: 1,
  isStarted: false,
  courseInfo: null,
  videoInfo: null,
  haveAssignment: false,
  articleInfo: null,
  testInfo: {},
  accessStatus: { status: "play", time: 0 },
};

const VideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.videoTypes.UPDATE_VIDEO_INFO:
      return {
        ...state,
        ...action.payload,
      };

    case types.videoTypes.UPDATE_COURSE_INFO:
      return {
        ...state,
        courseInfo: action.payload.courseInfo,
        isEnrolled: action.payload.isEnrolled,
        isRestricted: action.payload.isRestricted,
      };

    case types.videoTypes.UPDATE_ENROLLMENT_INFO:
      return {
        ...state,
        isEnrolled: action.payload.isEnrolled,
      };

    case types.videoTypes.UPDATE_PLAY_RATE:
      return {
        ...state,
        playrate: action.payload.rate,
      };

    case types.videoTypes.UPDATE_WATCH_LATER_STATUS:
      return {
        ...state,
        videoInfo: {
          ...state.videoInfo,
          isWishlisted: action.payload,
        },
      };

    case types.videoTypes.UPDATE_ARTICLE_INFO:
      return {
        ...state,
        articleInfo: action.payload.articleInfo,
      };

    case types.videoTypes.UPDATE_TEST_INFO:
      return {
        ...state,
        testInfo: {
          ...state.testInfo,
          ...action.payload.testInfo,
        },
      };

    default:
      return state;
  }
};

export default VideoReducer;
