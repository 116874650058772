import types from "../types";

const initialState = {
  fetchresume: 0,
  resetresume: 0,
};

const ResumeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.resumeTypes.FETCH_RESUME_SUCCESS: {
      return {
        ...state,
        fetchresume: action.payload,
      };
    }
    case types.resumeTypes.RESET_RESUME_DATA:
      return {
        ...state,
        resetresume: action.payload,
      };

    default:
      return state;
  }
};

export default ResumeReducer;
