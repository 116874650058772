import types from "../types";

const initialState = {
  bundleInfo: null,
};

const AddBundleDetails = (state = initialState, action) => {
  switch (action.type) {
    case types.UploadBundle.ADD_BUNDLE_DETAILS:
      return {
        ...state,
        bundleInfo: {
          ...state.bundleInfo,
          ...action.payload,
        },
      };

    case types.UploadBundle.ADD_CURRICULUM:
      return {
        ...state,
        payload,
      };

    default:
      return state;
  }
};

export default AddBundleDetails;
