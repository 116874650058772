import types from "../types";

const initialState = {
  fetchVideo: false,
  fetchStage: false,
};

const UploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.uploadTypes.FETCH_VIDEO:
      return {
        ...state,
        fetchVideo: !state.fetchVideo,
      };
    case types.uploadTypes.FETCH_STAGE:
      return {
        ...state,
        fetchStage: !state.fetchStage,
      };

    default:
      return state;
  }
};

export default UploadReducer;
