"use client";

import { PersistGate } from "redux-persist/integration/react";
import { toast, ToastContainer } from "react-toastify";
import { SessionProvider } from "next-auth/react";
import "react-toastify/dist/ReactToastify.css";
import { Inter } from "next/font/google";
import { Provider } from "react-redux";
import Script from "next/script";

import SocketContextComponent from "@/socket/Component";
import { store, persistor } from "../../configStore";
import Components from "@/components";
import ReduxContent from "./Content";
import "@/styles/index.scss";
import utils from "@/utils";

const inter = Inter({
  display: "swap",
  subsets: ["latin"],
  weight: ["300", "400", "500"],
  variable: "--ff-primary",
});

function RootLayout({ children }) {
  if (typeof window !== "undefined") {
    utils
      .onMessageListener()
      .then((payload) => {
        const { title, body } = payload.notification;
        toast.info(`${title}`);
      })
      .catch((err) => {
        toast.error(JSON.stringify(err));
      });
  }

  return (
    <html lang="en">
      <head>
        <Script id="Google Tag Manager" defer>
          {`(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-KKMN2GB6");`}
        </Script>
        <Script
          defer
          id="google-analytics-library"
          src="https://www.googletagmanager.com/gtag/js?id=G-Z6BHK5JEXR"
        ></Script>
        <Script defer id="google-analytics">
          {` window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "G-Z6BHK5JEXR");`}
        </Script>
        <Script defer id="heatmap">
          {`(function (h, e, a, t, m, p) {
        m = e.createElement(a);
        m.async = !0;
        m.src = t;
        p = e.getElementsByTagName(a)[0];
        p.parentNode.insertBefore(m, p);
      })(window, document, "script", "https://u.heatmap.it/log.js");`}
        </Script>
        <Script defer type="text/javascript" id="microsoft-clarity">
          {`(function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "ab3h139axo");`}
        </Script>
        {/* <!-- Facebook Pixel Code --> */}
        <Script defer id="facebook-pixel-1">
          {`!(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", "214677660025361");
      fbq("track", "PageView");`}
        </Script>
        {/* <!-- 2nd Meta Pixel Code (Added on Oct 10 2023) --> */}
        <Script defer id="facebook-pixel-2">
          {`!(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", "289248720578901");
      fbq("track", "PageView");`}
        </Script>
        <Script
          async
          id="gtag-manager"
          src="https://www.googletagmanager.com/gtm.js?id=GTM-KKMN2GB6"
        ></Script>
        <link rel="manifest" href="/manifest.json" />
        <meta
          property="og:image"
          content="https://www.cipherschools.com/og/default.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://ik.imagekit.io/cipherschools/CipherSchools/Cipherschools_192x192_8mBbikbYO.png"
        />
        <link
          rel="apple-touch-icon"
          href="https://ik.imagekit.io/cipherschools/CipherSchools/Cipherschools_192x192_8mBbikbYO.png"
        />
      </head>
      <body className={`${inter.variable} light common`} id="body">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <SocketContextComponent>
          <SessionProvider basePath="/next/api/auth">
            <Provider store={store}>
              <PersistGate
                loading={<Components.PageLoader type="page" />}
                persistor={persistor}
              >
                <ReduxContent>{children}</ReduxContent>
              </PersistGate>
            </Provider>
          </SessionProvider>
        </SocketContextComponent>
        <div id="modal-root"></div>
        {/* New Relic Browser Monitoring Script */}
      </body>
    </html>
  );
}

export default RootLayout;
