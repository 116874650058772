import types from "../types";

const initialState = {
  on: true,
  // hybrid: true,
  next: true,
  notes: false,
  comments: false,
  assignments: false,
};

const VideoBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.videoBarTypes.TOGGLE_BAR:
      return {
        ...state,
        on: !state.on,
      };
    // case types.videoBarTypes.TOGGLE_HYBRID_CONTENT:
    //   return {
    //     ...state,
    //     hybrid: true,
    //     next: false,
    //     notes: false,
    //     comments: false,
    //     assignments: false,
    //   };
    case types.videoBarTypes.TOGGLE_NEXT_CONTENT:
      return {
        ...state,
        // hybrid: false,
        next: true,
        notes: false,
        comments: false,
        assignments: false,
      };
    case types.videoBarTypes.TOGGLE_COMMENTS_CONTENT:
      return {
        ...state,
        // hybrid: false,
        next: false,
        notes: false,
        comments: true,
        assignments: false,
      };
    case types.videoBarTypes.TOGGLE_NOTES:
      return {
        ...state,
        // hybrid: false,
        next: false,
        notes: true,
        comments: false,
        assignments: false,
      };

    case types.videoBarTypes.TOGGLE_ASSIGNMENT_CONTENT:
      return {
        ...state,
        // hybrid: false,
        next: false,
        notes: false,
        comments: false,
        assignments: true,
      };

    default:
      return state;
  }
};

export default VideoBarReducer;
