import React, { Suspense, useCallback, useContext, useEffect } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import "shepherd.js/dist/css/shepherd.css";

import Modal from "@/components/shared/modal";
import SocketContext from "@/socket/Context";
import styles from "./layout.module.scss";
import services from "@/store/services";
import Components from "@/components";
import utils from "@/utils";
import apis from "@/api";

export default function ReduxContent({ children }) {
  const dispatch = useDispatch();
  const searchParams = useSearchParams();

  const { signinModal, creatorModal, feedbackModal, pointsModal } = useSelector(
    (state) => state.modal
  );
  const { socket } = useContext(SocketContext).SocketState;
  const points = useSelector((state) => state.loc_points);
  const user = useSelector((state) => state.user.users);
  const router = useRouter();

  const toggleLoginModal = () => dispatch(services.toggleSigninModal());
  const togglePointsModal = () => dispatch(services.togglePointsModal());
  const toggleCreatorReqModal = () => dispatch(services.toggleCreatorModal());
  const toggleFeedbackReqModal = () => dispatch(services.toggleFeedbackModal());

  // firebase token setup
  useEffect(() => {
    const firebaseToken = utils.getCookie("cs-firebase-token");
    if (user?.email && !firebaseToken)
      utils.requestPermission({
        userId: user?._id,
        email: user?.email,
        isIos: false,
      });
    // const signedCookies = utils.getCookie("CloudFront-Signature");
    // if (!signedCookies) apis.getKeys();
  }, [user]);

  useEffect(() => {
    const theme = utils.getCookie("theme");
    if (theme?.mode === "dark") {
      dispatch(
        services.toggleTheme("dark", theme?.change ? theme.change : "auto")
      );
    } else {
      dispatch(
        services.toggleTheme("light", theme?.change ? theme.change : "auto")
      );
    }
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      const theme = utils.getCookie("theme");
      const hour = new Date().getHours();
      if (theme?.change === "auto") {
        if (hour >= 7 && hour < 19) {
          if (theme?.mode !== "light") {
            dispatch(services.toggleTheme("light", "auto"));
          }
        } else {
          dispatch(services.toggleTheme("dark", "auto"));
        }
      }
    }, 1000);
    return () => clearTimeout(timeId);
  }, []);

  function afterLogout() {
    return (dispatch) =>
      Promise.all([
        dispatch(services.resetVideoMetaData()),
        dispatch(services.resetUserDetails()),
        dispatch(services.logoutUser()),
      ]);
  }

  const onHandleLogout = async () => {
    async function onSuccess() {
      dispatch(afterLogout()).then(() => {
        utils.removeFromLocalStorage("videoTour");
        toggleLoginModal();
      });
    }
    dispatch(
      services.promiseAlert(
        () => apis.logout(points, socket, user?._id),
        {
          pending: "Logging you out",
          success: "Successfully logged Out",
          error: "Can't logout you!",
        },
        onSuccess
      )
    );
  };

  const loginCases = useCallback(() => {
    const email = searchParams.get("email");
    const redirect = searchParams.get("redirect");
    const login = searchParams.get("login");
    const isLoggedIn = user?.email;
    const isSameUser = user?.email === email;
    if (isSameUser) {
      router.push(`${redirect}`);
      return;
    } else if (login && isLoggedIn) {
      onHandleLogout();
      return;
    } else if (login && !isLoggedIn) {
      //NOTE -  If not then on logout also popup comes
      toggleLoginModal();
      return;
    }
  }, [searchParams, user?.email]);

  useEffect(() => {
    loginCases();
  }, [loginCases]);

  useEffect(() => {
    if (!user?.email) {
      dispatch(services.fetchUser());
    }
  }, []);

  // //if user is fetched
  useEffect(() => {
    dispatch(services.fetchNetwork(user));
    dispatch(services.fetchPoints(user));
    dispatch(services.fetchEnrollment(user));
    dispatch(services.fetchUserDetails(user));
  }, [user]);

  return (
    <>
      <Suspense fallback={<Components.PageLoader />}>
        <>
          {children}
          <nav className={`${styles.nav}`}>
            <Components.BottomNavbar className={styles.navCard} />
          </nav>
        </>
      </Suspense>
      <Modal
        placement="top"
        showModal={signinModal}
        className="loginModal"
        setShowModal={toggleLoginModal}
      >
        <Components.LoginModal />
      </Modal>
      <Modal
        placement="center"
        showModal={pointsModal}
        className="pointsModal"
        setShowModal={togglePointsModal}
      >
        <Components.TimePointModal />
      </Modal>
      <Modal
        placement="top"
        showModal={creatorModal}
        className="feedbackModal"
        heading="Creator Access"
        setShowModal={toggleCreatorReqModal}
      >
        <Components.CreatorReqModal />
      </Modal>
      <Modal
        placement="top"
        showModal={feedbackModal}
        className="feedbackModal"
        heading="Send Feedback"
        setShowModal={toggleFeedbackReqModal}
      >
        <Components.FeedbackModal />
      </Modal>
    </>
  );
}
