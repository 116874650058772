import { io } from "socket.io-client";

class SocketService {
  constructor() {
    if (!SocketService.instance) {
      const devSocketURL = "http://localhost:3001";
      const testSocketURL = "https://qa.cipherschools.com";
      const prodSocketURL = "https://www.cipherschools.com";

      // Choose the socket URL based on the environment
      const socketURL =
        process.env.NODE_ENV === "production"
          ? prodSocketURL
          : process.env.NODE_ENV === "testing"
            ? testSocketURL
            : devSocketURL;

      this.socket = io(socketURL, {
        transports: ["websocket"],
        reconnection: true,
        withCredentials: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        timeout: 20000, // 20 seconds
        reconnectionAttempts: 5,
        autoConnect: false,
      });

      this.isConnected = false;
      this.isReconnecting = false;
      this.reconnectionAttempts = 0;
      this.initializeListeners();
      SocketService.instance = this;
    }
    return SocketService.instance;
  }

  connect() {
    if (!this.isConnected) {
      // console.info("Connecting socket...");
      this.socket.connect();
      this.isConnected = true;
      this.sendHandshake();
    } else {
      console.warn("Socket already connected. Skipping duplicate connection.");
    }
  }

  disconnect() {
    if (this.isConnected) {
      // console.info("Disconnecting socket...");
      this.socket.disconnect();
      this.isConnected = false;
      this.isReconnecting = false;
    }
  }

  sendHandshake = () => {
    if (!this.socket) return;
    this.socket.emit("handshake", (uid) => {
      console.info("User handshake callback message received", uid);
    });
  };

  initializeListeners() {
    this.socket.on("connect", () => {
      // console.info("Socket connected with ID:", this.socket.id);
      this.isConnected = true;
      this.isReconnecting = false;
    });

    this.socket.on("disconnect", () => {
      console.warn("Socket disconnected.");
      this.isConnected = false;
      this.scheduleReconnect();
    });

    this.socket.io.on("reconnect", (attempt) => {
      this.reconnectionAttempts = 0;
      console.info("Reconnected on attempt:", attempt);
      this.sendHandshake();
      this.isReconnecting = false;
    });

    this.socket.io.on("reconnect_attempt", (attempt) => {
      console.info("Reconnection attempt:", attempt);
      this.reconnectionAttempts = attempt;
    });

    this.socket.io.on("reconnect_error", (error) => {
      console.info("Reconnection error:", error);
    });

    this.socket.io.on("reconnect_failed", () => {
      console.info("Reconnection failed.");
      if (this.reconnectionAttempts > 5) {
        console.error("Max reconnection attempts reached.");
        this.isReconnecting = false;
      }
    });
  }

  scheduleReconnect() {
    if (this.isReconnecting || this.isConnected) return; // Avoid multiple reconnections

    this.isReconnecting = true; // Mark as reconnecting
    const delay = Math.min(
      1000 * Math.pow(2, this.reconnectionAttempts),
      30000
    );

    console.info(`Attempting to reconnect in ${delay / 1000} seconds...`);

    setTimeout(() => {
      if (!this.isConnected) {
        console.info("Reconnecting socket...");
        this.socket.connect();
      }
    }, delay);
  }

  getSocket() {
    return this.socket;
  }
}

const socketInstance = new SocketService();
export default socketInstance;
