import types from "../types";

const initialState = {
  playrate: 1,
  player: null,
  quality: undefined,
};

const Video_Preference = (state = initialState, action) => {
  switch (action.type) {
    case types.videoPreference.PLAYER_INITALIZE:
      return {
        ...initialState,
        player: action.payload?.player,
      };
    case types.videoPreference.UPDATE_VIDEO_PREFERENCE:
      return {
        ...initialState,
        playrate: action.payload.playrate,
        quality: action.payload.quality,
      };

    default:
      return state;
  }
};

export default Video_Preference;
