import types from "../types";

const initialState = false;

const ThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.themeTypes.TOGGLE_DARK_MODE:
      return action.payload;

    default:
      return state;
  }
};

export default ThemeReducer;
